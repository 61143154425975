// Import Modules
const dashboardModule = () => import('@/modules/Dashboard')
const usersModule = () => import('@/modules/Users')
const usersCreateModule = () => import('@/modules/Users/modules/UsersCreate')
const usersProfileModule = () => import('@/modules/Users/modules/UsersProfile')
const alphabetModule = () => import('@/modules/Alphabet')
const alphabetViewModule = () => import('@/modules/Alphabet/modules/AlphabetView')
const alphabetCreateModule = () => import('@/modules/Alphabet/modules/AlphabetCreate')
const numbersModule = () => import('@/modules/Numbers')
const numbersCreateModule = () => import('@/modules/Numbers/modules/NumbersCreate')
const numbersViewModule = () => import('@/modules/Numbers/modules/NumbersView')
const colorsModule = () => import('@/modules/Colors')
const colorsCreateModule = () => import('@/modules/Colors/modules/ColorsCreate')
const colorsViewModule = () => import('@/modules/Colors/modules/ColorsView')
const colorsFiguresModule = () => import('@/modules/ColorsFigures')
const colorsFiguresViewModule = () => import('@/modules/ColorsFigures/modules/ColorsFiguresView')
const colorsFiguresCreateModule = () => import('@/modules/ColorsFigures/modules/ColorsFiguresCreate')
const puzzlesModule = () => import('@/modules/Puzzles')
const puzzlesViewModule = () => import('@/modules/PuzzlesView')
const puzzlesCreateModule = () => import('@/modules/PuzzlesCreate')
const memoryCardsImagesModule = () => import('@/modules/MemoryCardsImages')
const memoryCardsImagesViewModule = () => import('@/modules/MemoryCardsImages/modules/MemoryCardsImagesView')
const memoryCardsImagesCreateModule = () => import('@/modules/MemoryCardsImages/modules/MemoryCardsImagesCreate')
const questionsModule = () => import('@/modules/Questions')
const questionsCreateModule = () => import('@/modules/Questions/modules/QuestionsCreate')
const questionsViewModule = () => import('@/modules/Questions/modules/QuestionsView')
const notificationsModule = () => import('@/modules/Notifications')
const notificationsCreateModule = () => import('@/modules/Notifications/modules/NotificationsCreate')
const poemsModule = () => import('@/modules/Poems')
const poemsViewModule = () => import('@/modules/Poems/modules/PoemsView')
const poemsCreateModule = () => import('@/modules/Poems/modules/PoemsCreate')
const fairyTalesModule = () => import('@/modules/FairyTales')
const fairyTalesCreateModule = () => import('@/modules/FairyTales/modules/FairyTalesCreate')
const fairyTalesViewModule = () => import('@/modules/FairyTales/modules/FairyTalesView')
const settingsModule = () => import('@/modules/Settings')

// Export
export default [
    // Dashboard
    {
        path: '/admin/dashboard',
        component: dashboardModule,
        meta: { auth: true },
        name: 'admin.dashboard'
    },

    // Users
    {
        path: '/admin/users',
        component: usersModule,
        meta: { auth: true },
        name: 'admin.users'
    },

    // Users Create
    {
        path: '/admin/users/create',
        component: usersCreateModule,
        meta: { auth: true },
        name: 'admin.users.create'
    },

    // Users Profile
    {
        path: '/admin/users/profile/:id',
        component: usersProfileModule,
        meta: { auth: true },
        name: 'admin.users.profile'
    },

    // Alphabet
    {
        path: '/admin/alphabet',
        component: alphabetModule,
        meta: { auth: true },
        name: 'admin.alphabet'
    },

    // Alphabet View
    {
        path: '/admin/alphabet/view/:id',
        component: alphabetViewModule,
        meta: { auth: true },
        name: 'admin.alphabet.view'
    },

    // Alphabet Create
    {
        path: '/admin/alphabet/create',
        component: alphabetCreateModule,
        meta: { auth: true },
        name: 'admin.alphabet.create'
    },

    // Numbers
    {
        path: '/admin/numbers',
        component: numbersModule,
        meta: { auth: true },
        name: 'admin.numbers'
    },

    // Numbers Create
    {
        path: '/admin/numbers/create',
        component: numbersCreateModule,
        meta: { auth: true },
        name: 'admin.numbers.create'
    },

    // Numbers View
    {
        path: '/admin/numbers/view/:id',
        component: numbersViewModule,
        meta: { auth: true },
        name: 'admin.numbers.view'
    },

    // Colors
    {
        path: '/admin/colors',
        component: colorsModule,
        meta: { auth: true },
        name: 'admin.colors'
    },

    // Colors Create
    {
        path: '/admin/colors/create',
        component: colorsCreateModule,
        meta: { auth: true },
        name: 'admin.colors.create'
    },

    // Colors View
    {
        path: '/admin/colors/view/:id',
        component: colorsViewModule,
        meta: { auth: true },
        name: 'admin.colors.view'
    },

    // Colors Figures
    {
        path: '/admin/colors/figures',
        component: colorsFiguresModule,
        meta: { auth: true },
        name: 'admin.colors.figures'
    },

    // Colors Figures View
    {
        path: '/admin/colors/figures/view/:id',
        component: colorsFiguresViewModule,
        meta: { auth: true },
        name: 'admin.colors.figures.view'
    },

    // Colors Figures Create
    {
        path: '/admin/colors/figures/create',
        component: colorsFiguresCreateModule,
        meta: { auth: true },
        name: 'admin.colors.figures.create'
    },

    // Puzzles
    {
        path: '/admin/puzzles',
        component: puzzlesModule,
        meta: { auth: true },
        name: 'admin.puzzles'
    },

    // Puzzles View
    {
        path: '/admin/puzzles/view/:id',
        component: puzzlesViewModule,
        meta: { auth: true },
        name: 'admin.puzzles.view'
    },

    // Puzzles Create
    {
        path: '/admin/puzzles/create',
        component: puzzlesCreateModule,
        meta: { auth: true },
        name: 'admin.puzzles.create'
    },

    // Memory Cards Images
    {
        path: '/admin/memory-cards/images',
        component: memoryCardsImagesModule,
        meta: { auth: true },
        name: 'admin.memorycards.images'
    },

    // Memory Cards Images View
    {
        path: '/admin/memory-cards/images/view/:id',
        component: memoryCardsImagesViewModule,
        meta: { auth: true },
        name: 'admin.memorycards.images.view'
    },

    // Memory Cards Images View
    {
        path: '/admin/memory-cards/images/create',
        component: memoryCardsImagesCreateModule,
        meta: { auth: true },
        name: 'admin.memorycards.images.create'
    },

    // Questions
    {
        path: '/admin/questions',
        component: questionsModule,
        meta: { auth: true },
        name: 'admin.questions'
    },

    // Questions Create
    {
        path: '/admin/questions/create',
        component: questionsCreateModule,
        meta: { auth: true },
        name: 'admin.questions.create'
    },

    // Questions View
    {
        path: '/admin/questions/view/:id',
        component: questionsViewModule,
        meta: { auth: true },
        name: 'admin.questions.view'
    },

    // Poems
    {
        path: '/admin/poems',
        component: poemsModule,
        meta: { auth: true },
        name: 'admin.poems'
    },
    
    // PoemsView
    {
        path: '/admin/poems/view/:id',
        component: poemsViewModule,
        meta: { auth: true },
        name: 'admin.poems.view'
    },
    
    // PoemsCreate
    {
        path: '/admin/poems/create',
        component: poemsCreateModule,
        meta: { auth: true },
        name: 'admin.poems.create'
    },

    // Notifications
    {
        path: '/admin/notifications',
        component: notificationsModule,
        meta: { auth: true },
        name: 'admin.notifications'
    },

    // PoemsCreate
    {
        path: '/admin/notifications/create',
        component: notificationsCreateModule,
        meta: { auth: true },
        name: 'admin.notifications.create'
    },

    // Fairy Tales
    {
        path: '/admin/fairy-tales',
        component: fairyTalesModule,
        meta: { auth: true },
        name: 'admin.fairytales'
    },

    // Fairy Tales Create
    {
        path: '/admin/fairy-tales/create',
        component: fairyTalesCreateModule,
        meta: { auth: true },
        name: 'admin.fairytales.create'
    },

    // Fairy Tales View
    {
        path: '/admin/fairy-tales/view/:id',
        component: fairyTalesViewModule,
        meta: { auth: true },
        name: 'admin.fairytales.view'
    },

    // Settings
    {
        path: '/settings',
        component: settingsModule,
        meta: { auth: true },
        name: 'settings'
    }
]