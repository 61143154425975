import loaderComponent from '@/components/Loader'
import { mapGetters } from 'vuex'
// import { initializeFirebase } from '@/utils/firebase.js';

export default {
    name: 'App',
    components: {
        loaderComponent
    },
    computed: {
        ...mapGetters({
            displayLoader: 'getDisplayLoader'
        })
    },
    created() {
        // initializeFirebase();
    }
}
